<template>
    <div class="Spacebase">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/Spacebase/banner.png" alt="">
                <img class="bg2" src="@/assets/Home/Purpleblock.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>SPACEBASE</h1>
                    <p>Burn tSPACE to create your spacebase.</p>
                    <p>Both the creator and member will earn extra</p>
                    <p>reward, when the member harvests the farm reward.</p>
                    <div>
                        <p class="rules" @click="messageBoxShow = true;messageType = 'rules'">Rules</p>
                        <a class="howtouse" href="https://docs.spacefi.io/products/spacebase" target="_block">How to use</a>
                    </div>
                </div>
                <div class="totalnumber">
                    <p class="num">{{totalNumberOfSpacebase}}</p>
                    <p class="text">
                        <span>Total number</span>   
                        <span>of Spacebases</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="basedata">
            <div class="center connectBoxW">
                <div class="basetypeandcreate">
                    <div class="tab">
                        <p :class="tabIndex == 0 ? 'active' : ''" @click="tabIndex = 0">Spacebase</p>
                        <p :class="tabIndex == 1 ? 'active' : ''" @click="tabIndex = 1">My Spacebase</p>
                    </div>
                    <div v-if="myBase.length == 0" class="create" @click="createSpacebase">+ Create a Spacebase</div>
                </div>
                <div class="searchbox" v-if="tabIndex == 0">
                    <i class="el-icon-search"></i>
                    <input type="text" v-model="searchVal" @keyup.enter="searchBtn" placeholder="Search Spacebase">
                </div>
                <div v-if="tabIndex == 0" class="allbasesbox">
                    <p v-if="spacebaseeListLoading" style="margin-top:100px;color:#e5e5ba;" class="baseLaoding">
                        <v-LoadingWarp :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                    <p v-if="allBaseList.length == 0 && !spacebaseeListLoading" style="display:flex;justify-content: center;align-items: center;color:#e5e5ba;">
                        <img class="nodataImg" style="width: 100px;" src="../assets/logos/spacefiFFF.png" alt=""> No data available
                    </p>
                    <ul v-if="!spacebaseeListLoading" class="list">
                        <li v-for="(item, index) in allBaseList" :key="index" class="item">
                            <div class="creator">
                                <p class="name">
                                    <span>{{changeAddress(item.name)}}</span>
                                    <span>Creator</span>
                                </p>
                                <div class="join" @click="cardJoinBtn(item,1)">+ Join</div>
                            </div>
                            <div class="spaceBaseintroduction">
                                <p class="title">SpaceBase Introduction:</p>
                                <div :class="item.notesShow ? 'text textShow' : 'text'" v-if="item.notes != ''">
                                    <div v-html="item.notes"></div>
                                    <i :class="item.notesShow ? 'el-icon-arrow-up i' : 'el-icon-arrow-down i'" @click="introductionShow(item)"></i>
                                </div>
                                <p class="text" v-else>No profile yet.</p>
                            </div>
                            <div class="border"></div>
                            <ul class="info">
                                <li class="Memberplace">
                                    <p>Member place</p>
                                    <p>{{item.totalUnit}}</p>
                                </li>
                                <li class="Joinedmembers">
                                    <p>Joined members</p>
                                    <p>{{item.joinedMember}}</p>
                                </li>
                                <li class="SpacebaceTVL">
                                    <p>Spacebace TVL</p>
                                    <p>${{item.tvl.toFixed(3)}}</p>
                                </li>
                                <li class="Spacebasetotalminingrewards">
                                    <p>Spacebase total mining rewards</p>
                                    <p>
                                        <span v-if="item.award != 0">{{item.award.toFixed(6)}} {{tokenSymbol}}</span>
                                        <span>{{item.xaward.toFixed(6)}}  {{xtokenSymbol}}</span>
                                        <span v-if="item.awardLptoken1 != 0">{{item.awardLptoken1.toFixed(6)}} {{lpArr[0].symbol}}</span>
                                        <span v-if="item.awardLptoken2 != 0">{{item.awardLptoken2.toFixed(6)}} {{lpArr[1].symbol}}</span>
                                        <span v-if="item.awardLptoken3 != 0">{{item.awardLptoken3.toFixed(6)}} {{lpArr[2].symbol}}</span>
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="tabIndex == 1" class="mybasebox">
                    <p v-if="spacebaseeListLoading" style="margin-top:200px;color:#e5e5ba;"  class="baseLaoding">
                        <v-LoadingWarp :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                    <p v-if="myBase.length == 0 && !spacebaseeListLoading" style="display:flex;align-items: center;justify-content: center;color: #feffbc;">
                        <img class="nodataImg" style="width: 100px;" src="../assets/logos/spacefiFFF.png" alt=""> No data available
                    </p>
                    <div class="mycreate" v-if="myBase.length > 0 && !spacebaseeListLoading">
                        <div v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="ViewMembers" @click="messageBoxShow = true;messageType = 'memberlist'"> <span>View Members ({{myBase[0].userList.length}})</span> <i class="el-icon-arrow-right"></i></div>
                        <div class="creator">
                            <p class="name">
                                <span>{{changeAddress(myBase[0].name)}}<span v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="changebtn purplebtn" style="margin-top:0;" @click="messageBoxShow = true;messageType = 'basename';"><span class="pen"></span> <span></span> Change</span></span>
                                <span>Creator</span>
                            </p>
                            <!-- <div v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="ViewMembers" @click="messageBoxShow = true;messageType = 'memberlist'"> <span>View Members ({{myBase[0].userList.length}})</span> <i class="el-icon-arrow-right"></i></div> -->
                        </div>
                        <div class="spaceBaseintroduction">
                            <p class="title">SpaceBase Introduction:<span v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="changebtn" @click="messageBoxShow = true;messageType = 'spacebaseintroduction'"><span class="pen"></span> <span></span> Change</span></p>
                            <div :class="myBase[0].notesShow ? 'text textShow' : 'text'" v-if="myBase[0].notes != ''">
                                <div v-html="myBase[0].notes"></div>
                                <i :class="myBase[0].notesShow ? 'el-icon-arrow-up i' : 'el-icon-arrow-down i'" @click="introductionShow(myBase[0])"></i>
                            </div>
                            <p class="text" v-else>No profile yet.</p>
                        </div>
                        <div class="border"></div>
                        <ul class="info">
                            <li class="Memberplace">
                                <p>Member place</p>
                                <p>{{myBase[0].totalUnit}}</p>
                            </li>
                            <li class="Joinedmembers">
                                <p>Joined members</p>
                                <p>{{myBase[0].joinedMember}}</p>
                            </li>
                            <li class="SpacebaceTVL">
                                <p>Spacebace TVL</p>
                                <p>${{myBase[0].tvl.toFixed(3)}}</p>
                            </li>
                            <li class="MemberJoiningprice">
                                <p>Member Joining price<span v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="changebtn" @click="messageBoxShow = true;messageType = 'memberjoiningbaseprice';changeTokens()"><span class="pen"></span> <span></span> Change</span></p>
                                <p>
                                    <span v-if="myBase[0].nodeFeeType == 3"> — — — </span>
                                    <span v-if="myBase[0].nodeFeeType == 0 || myBase[0].nodeFeeType == 1">{{myBase[0].fee}} {{tokenSymbol}}</span>
                                    <span v-if="myBase[0].nodeFeeType == 0 || myBase[0].nodeFeeType == 2">{{myBase[0].xfee}} {{xtokenSymbol}}</span>
                                </p>
                            </li>
                            <li class="Spacebasetotalminingrewards">
                                <p>Spacebase total mining rewards</p>
                                <p>
                                    <span v-if="myBase[0].award != 0">{{myBase[0].award.toFixed(6)}} {{tokenSymbol}}</span>
                                    <span>{{myBase[0].xaward.toFixed(6)}}  {{xtokenSymbol}}</span>
                                    <span v-if="myBase[0].awardLptoken1 != 0">{{myBase[0].awardLptoken1.toFixed(6)}} {{lpArr[0].symbol}}</span>
                                    <span v-if="myBase[0].awardLptoken2 != 0">{{myBase[0].awardLptoken2.toFixed(6)}} {{lpArr[1].symbol}}</span>
                                    <span v-if="myBase[0].awardLptoken3 != 0">{{myBase[0].awardLptoken3.toFixed(6)}} {{lpArr[2].symbol}}</span>
                                </p>
                            </li>
                            <li class="GroupChatLink">
                                <p>Group Chat Link<span v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="changebtn" @click="messageBoxShow = true;messageType = 'groupchatlink'"><span class="pen"></span> <span></span> Change</span></p>
                                <p v-if="myBase[0].social == ''"> — — — </p>
                                <p v-else><a :href="myBase[0].social" target="_blank" rel="noopener noreferrer">{{myBase[0].social}}</a></p>
                            </li>
                        </ul>
                        <div v-if="myBase[0].owner.toLowerCase() == userAddress.toLowerCase()" class="mybottombtns">
                            <div class="Addqota" @click="messageBoxShow = true;messageType = 'addquota'">Add qota</div>
                            <div class="Invitemember" @click="messageBoxShow = true;messageType = 'invitemember'">Invite member</div>
                        </div>
                        <div v-else class="mytype">
                            <div class="Joined">Joined</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="messageBox rulesmessageBox" v-if="messageBoxShow" @click="closemessage">
            <div class="connectBox connectBoxW">
                <div v-if="messageType == 'createaspacebase'" class="message0" @click.stop>
                    <h1>
                        Create a Spacebase
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="Pleaseselectthetokenyouwanttopaywith">
                        <p class="smalltitle">Please select the token you want to pay with<span style="color:#f00;">*</span></p>
                        <div class="box">
                            <div :class=" createToken == 0 ? 'active' : '' " @click="createToken = 0"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>SPACE</span></div>
                            <div :class=" createToken == 1 ? 'active' : '' " @click="createToken = 1"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>xSPACE</span></div>
                        </div>
                    </div>
                    <div class="Memberprice">
                        <p class="smalltitle">Member price<span style="color:#f00;">*</span></p>
                        <div>
                            <input type="number" v-model="spacebaseMember" :placeholder="'No less than '+minNumber+' places'">
                        </div>
                    </div>
                    <div class="Memberjoiningprice">
                        <p class="smalltitle">Member joining price</p>
                        <div class="box">
                            <div class="iptbox">
                                <div :class=" joiningSPACEToken ? 'active' : '' " @click="joiningSPACEToken = !joiningSPACEToken"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>SPACE</span></div>
                                <div><input type="number" v-model="joiningSPACEPrice" placeholder="Free" :disabled="!joiningSPACEToken"></div>
                            </div>
                            <div class="iptbox">
                                <div :class=" joiningxSPACEToken ? 'active' : '' " @click="joiningxSPACEToken = !joiningxSPACEToken"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>xSPACE</span></div>
                                <div><input type="number" v-model="joiningxSPACEPrice" placeholder="Free" :disabled="!joiningxSPACEToken"></div>
                            </div>
                        </div>
                        <p class="tip">You can set a price or 0 (free) for members to join.</p>
                    </div>
                    <div class="Spacebasename">
                        <p class="smalltitle">Spacebase name</p>
                        <div>
                            <input type="text" v-model="spacebaseName" :placeholder="'Default:'+userAddress">
                        </div>
                    </div>
                    <div class="GroupChatLink">
                        <p class="smalltitle">Group Chat Link (optional)</p>
                        <div>
                            <input type="text" v-model="groupChatLink" placeholder=" You can create a Telegram or Discord group for members to join">
                        </div>
                    </div>
                    <div class="SpaceBaseIntroduction">
                        <p class="smalltitle">SpaceBase Introduction (optional)</p>
                        <div>
                            <quill-editor 
                                style="margin-top:-20px;"
                                class="ql-editor"
                                v-model="spacebaseIntroduction"
                                ref="myQuillEditor" 
                                :options="editorOption" 
                                @change="changeIntroduction($event)">
                            </quill-editor>
                            <div class="editor_length">{{ TiLength }}/140</div>
                        </div>
                    </div>
                    <p class="tipyellow">
                        <span></span>
                        <span>You can only either create or join one spacebase.</span>    
                    </p>
                    <p class="tipyellow">
                        <span></span>
                        <span>Spacebase reward will be accumulated when the member harvests the farm reward.</span>
                    </p>
                    <p class="tipcreate">According to the Space real-time data, a single place currently needs to cost <span>{{createToken == 0 ? unitPrice : xunitPrice}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}}</span></p>
                    <p class="tipprice">Total {{spacebaseMember ? createToken == 0 ? spacebaseMember*unitPrice : spacebaseMember*xunitPrice : 0}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}} to be burned</p>
                    <p class="tipprice">Your balance: {{createToken == 0 ? SPACEBalanceofText : xSPACEBalanceofText}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}}</p>
                    <div class="messagebtn" @click="createnow">Create Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'jointhespacebase' && jointhespacebase.length == 0" class="message0 jointhespacebase" @click.stop>
                    <div class="warp" style="color:#feffbc;">
                        <v-LoadingWarp :imgSize="3"></v-LoadingWarp> Loading . . .
                    </div>
                    <h1>
                        Join the Spacebase
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="cretor">
                        <p class="name">
                            <span> — — — </span>
                        </p>
                    </div>
                    <div class="spaceBaseintroduction">
                        <p class="title">SpaceBase Introduction:</p>
                        <p class="text" >No profile yet.</p>
                    </div>
                    <div class="border"></div>
                    <ul class="info">
                        <li class="Memberplace">
                            <p>Member place</p>
                            <p> — — — </p>
                        </li>
                        <li class="Joinedmembers">
                            <p>Joined members</p>
                            <p> — — — </p>
                        </li>
                        <li class="SpacebaceTVL">
                            <p>Spacebace TVL</p>
                            <p> — — — </p>
                        </li>
                        <li class="MemberJoiningprice">
                            <p>Member Joining price</p>
                            <p>
                                <span> — — — </span>
                            </p>
                        </li>
                        <li class="Spacebasetotalminingrewards">
                            <p>Spacebase total mining rewards</p>
                            <p>
                                <span > — — — </span>
                            </p>
                        </li>
                        <li class="GroupChatLink">
                            <p>Group Chat Link</p>
                            <p> — — — </p>
                        </li>
                    </ul>
                    
                    <div class="messagebtn">Join Now</div>
                    <p class="tipyellow">
                        <span></span>
                        <span>Reminder: You can only either create or join one spacebase.</span>    
                    </p>
                </div>
                <div v-if="messageType == 'jointhespacebase' && jointhespacebase.length > 0" class="message0 jointhespacebase" @click.stop>
                    <h1>
                        Join the Spacebase
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="creator">
                        <p class="name">
                            <span>{{changeAddress(jointhespacebase[0].name)}}</span>
                            <span>Creator</span>
                        </p>
                    </div>
                    <div class="spaceBaseintroduction">
                        <p class="title">SpaceBase Introduction:</p>
                        <div :class="jointhespacebase[0].notesShow ? 'text textShow' : 'text'" v-if="jointhespacebase[0].notes != ''">
                            <div v-html="jointhespacebase[0].notes"></div>
                            <i :class="jointhespacebase[0].notesShow ? 'el-icon-arrow-up i' : 'el-icon-arrow-down i'" @click="introductionShow(jointhespacebase[0])"></i>
                        </div>
                        <p class="text" v-else>No profile yet.</p>
                    </div>
                    <div class="border"></div>
                    <ul class="info">
                        <li class="Memberplace">
                            <p>Member place</p>
                            <p>{{jointhespacebase[0].totalUnit}}</p>
                        </li>
                        <li class="Joinedmembers">
                            <p>Joined members</p>
                            <p>{{jointhespacebase[0].joinedMember}}</p>
                        </li>
                        <li class="SpacebaceTVL">
                            <p>Spacebace TVL</p>
                            <p>${{jointhespacebase[0].tvl.toFixed(3)}}</p>
                        </li>
                        <li class="MemberJoiningprice">
                            <p>Member Joining price</p>
                            <p>
                                <span v-if="jointhespacebase[0].nodeFeeType == 3"> — — — </span>
                                <span v-if="jointhespacebase[0].nodeFeeType == 0 || jointhespacebase[0].nodeFeeType == 1">{{jointhespacebase[0].fee}} {{tokenSymbol}}</span>
                                <span v-if="jointhespacebase[0].nodeFeeType == 0 || jointhespacebase[0].nodeFeeType == 2">{{jointhespacebase[0].xfee}} {{xtokenSymbol}}</span>
                            </p>
                        </li>
                        <li class="Spacebasetotalminingrewards">
                            <p>Spacebase total mining rewards</p>
                            <p>
                                <span v-if="jointhespacebase[0].award != 0">{{jointhespacebase[0].award.toFixed(6)}} {{tokenSymbol}}</span>
                                <span>{{jointhespacebase[0].xaward.toFixed(6)}}  {{xtokenSymbol}}</span>
                                <span v-if="jointhespacebase[0].awardLptoken1 != 0">{{jointhespacebase[0].awardLptoken1.toFixed(6)}} {{lpArr[0].symbol}}</span>
                                <span v-if="jointhespacebase[0].awardLptoken2 != 0">{{jointhespacebase[0].awardLptoken2.toFixed(6)}} {{lpArr[1].symbol}}</span>
                                <span v-if="jointhespacebase[0].awardLptoken3 != 0">{{jointhespacebase[0].awardLptoken3.toFixed(6)}} {{lpArr[2].symbol}}</span>
                            </p>
                        </li>
                        <li class="GroupChatLink">
                            <p>Group Chat Link</p>
                            <p v-if="jointhespacebase[0].social == ''"> — — — </p>
                            <p v-else><a :href="jointhespacebase[0].social" target="_blank" rel="noopener noreferrer">{{jointhespacebase[0].social}}</a></p>
                        </li>
                    </ul>
                    <div v-if="jointhespacebase[0].nodeFeeType != 3" class="Pleaseselectthetokenyouwanttopaywith" style="margin-top: 30px;">
                        <p class="smalltitle">Please select the token you want to pay with<span style="color:#f00;">*</span></p>
                        <div class="box">
                            <div v-if="jointhespacebase[0].nodeFeeType == 0 || jointhespacebase[0].nodeFeeType == 1" :class=" joinToken == 0 ? 'active' : '' " @click="joinToken = 0"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>{{tokenSymbol}}</span></div>
                            <div v-if="jointhespacebase[0].nodeFeeType == 0 || jointhespacebase[0].nodeFeeType == 2" :class=" joinToken == 1 ? 'active' : '' " @click="joinToken = 1"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>{{xtokenSymbol}}</span></div>
                        </div>
                    </div>
                    <div class="messagebtn" @click="cardJoinBtn(jointhespacebase[0],0)">Join Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <p class="tipyellow">
                        <span></span>
                        <span>Reminder: You can only either create or join one spacebase.</span>    
                    </p>
                </div>

                <div v-if="messageType == 'joinaspacebase'" class="message0" @click.stop>
                    <h1>
                        Join a Spacebase
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div v-if="activeItem.nodeFeeType != 3" class="memberjoiningprice">
                        <p class="smalltitle">Member joining price<span style="color:#f00;">*</span></p>
                        <div class="box">
                            <div v-if="activeItem.nodeFeeType == 3"> — — — </div>
                            <div v-if="activeItem.nodeFeeType == 0 || activeItem.nodeFeeType == 1">{{activeItem.fee}} {{tokenSymbol}}</div>
                            <div v-if="activeItem.nodeFeeType == 0 || activeItem.nodeFeeType == 2">{{activeItem.xfee}} {{xtokenSymbol}}</div>
                            <!-- <div>10 SPACE</div>
                            <div>11 xSPACE</div> -->
                        </div>
                    </div>
                    <div v-if="activeItem.nodeFeeType != 3" class="Pleaseselectthetokenyouwanttopaywith">
                        <p class="smalltitle">Please select the token you want to pay with<span style="color:#f00;">*</span></p>
                        <div class="box">
                            <div v-if="activeItem.nodeFeeType == 0 || activeItem.nodeFeeType == 1" :class=" joinToken == 0 ? 'active' : '' " @click="joinToken = 0"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>{{tokenSymbol}}</span></div>
                            <div v-if="activeItem.nodeFeeType == 0 || activeItem.nodeFeeType == 2" :class=" joinToken == 1 ? 'active' : '' " @click="joinToken = 1"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>{{xtokenSymbol}}</span></div>
                        </div>
                    </div>
                    <p class="tipyellow">
                        <span></span>
                        <span>You can only either create or join one spacebase.</span>    
                    </p>
                    <p class="tipyellow">
                        <span></span>
                        <span>Spacebase reward will be accumulated when the member harvests the farm reward.</span>
                    </p>
                    <div class="messagebtn" @click="joinNow">Join Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'basename'" class="message0" @click.stop>
                    <h1>
                        Modify base name
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="Spacebasename">
                        <div style="width: 100%; margin-left: 0; margin-bottom: 20px;">
                            <input type="text" v-model="spacebaseName" placeholder="Modify base name">
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="changeBaseName">Modify Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'memberlist'" class="message0" @click.stop>
                    <h1>
                        Member List
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div v-if="myBase[0].userList.length == 0">
                        <p style="display:flex;align-items: center;justify-content: center;color: #feffbc;"><img class="nodataImg" style="width:80px;" src="../assets/logos/spacefiFFF.png" alt=""> No data available</p>
                    </div>
                    <div v-else class="addresslist">
                        <p class="smalltitle" v-for="(item,index) in myBase[0].userList" :key="index"><a :href="'https://testnet.blastscan.io/address/'+item" target="_blank" rel="noopener noreferrer">{{item}}</a></p>
                    </div>
                </div>

                <div v-if="messageType == 'spacebaseintroduction'" class="message0" @click.stop>
                    <h1>
                        SpaceBase Introduction
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="SpaceBaseIntroduction">
                        <div style="width: 100%; margin-left: 0; margin-bottom: 20px;">
                            <quill-editor 
                                style="margin-top:-20px;"
                                class="changeql-editor"
                                ref="myQuillEditor" 
                                v-model="spacebaseIntroduction"
                                :options="editorOption" 
                                @change="changeIntroduction($event)">
                            </quill-editor>
                            <div class="editor_length">{{ TiLength }}/140</div>
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="changeBaseIntroduction">Change Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'memberjoiningbaseprice'" class="message0" @click.stop>
                    <h1>
                        Member joining base price
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="Memberjoiningprice">
                        <div class="box" style="margin-left: 0;">
                            <div class="iptbox">
                                <div :class=" joiningSPACEToken ? 'active' : '' " @click="joiningSPACEToken = !joiningSPACEToken"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>SPACE</span></div>
                                <div><input type="number" v-model="joiningSPACEPrice" placeholder="Free" :disabled="!joiningSPACEToken"></div>
                            </div>
                            <div class="iptbox">
                                <div :class=" joiningxSPACEToken ? 'active' : '' " @click="joiningxSPACEToken = !joiningxSPACEToken"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>xSPACE</span></div>
                                <div><input type="number" v-model="joiningxSPACEPrice" placeholder="Free" :disabled="!joiningxSPACEToken"></div>
                            </div>
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="changeJoinBasePrice">Modify Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'groupchatlink'" class="message0" @click.stop>
                    <h1>
                        Group Chat Link
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="GroupChatLink">
                        <div style="width: 100%; margin-left: 0; margin-bottom: 20px;">
                            <input type="text" v-model="groupChatLink" placeholder=" You can create a Telegram or Discord group for members to join">
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="changeBaseGroup">Change Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'addquota'" class="message0" @click.stop>
                    <h1>
                        Add quota
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="Pleaseselectthetokenyouwanttopaywith">
                        <p class="smalltitle">Please select the token you want to pay with<span style="color:#f00;">*</span></p>
                        <div class="box">
                            <div :class=" createToken == 0 ? 'active' : '' " @click="createToken = 0"><img src="https://arweave.net/j62sjUxXM5H9i2PMB-X9pY6WWvmrmh3tLnJMnkQLig4" alt=""><span>SPACE</span></div>
                            <div :class=" createToken == 1 ? 'active' : '' " @click="createToken = 1"><img src="https://arweave.net/iIUrRq1tlZgXz93cqgB9FhP7Yvv0ZL4l4Z6BTq_ZfyI" alt=""><span>xSPACE</span></div>
                        </div>
                    </div>
                    <div class="Memberprice">
                        <p class="smalltitle">Member price<span style="color:#f00;">*</span></p>
                        <div>
                            <input type="number" v-model="spacebaseMember" placeholder="Please enter the number of additions">
                        </div>
                    </div>
                    <p class="tipcreate">According to the Space real-time data, a single place currently needs to cost <span>{{createToken == 0 ? unitPrice : xunitPrice}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}}</span></p>
                    <p class="tipprice">Total {{spacebaseMember ? createToken == 0 ? spacebaseMember*unitPrice : spacebaseMember*xunitPrice : 0}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}} to be burned</p>
                    <p class="tipprice">Your balance: {{createToken == 0 ? SPACEBalanceofText : xSPACEBalanceofText}} {{createToken == 0 ? tokenSymbol : xtokenSymbol}}</p>
                    <div class="messagebtn" @click="changeBaseMember">Add Now <v-LoadingWarpBlack v-if="btnLoading" :imgSize="1"></v-LoadingWarpBlack></div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'invitemember'" class="message0" @click.stop>
                    <h1>
                        Invite member
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="Memberinvitationlink">
                        <p class="smalltitle">Member invitation link:</p>
                        <div style="margin-left: 0;">
                            <input type="text" disabled :value="linkUrl+'addresss='+userAddress+'&code='+myBase[0].code">
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="copyLink">Copy invitation link</div>
                    <!-- <p class="tipgas">Estimated Gas Refund: 75%-95%</p> -->
                </div>

                <div v-if="messageType == 'rules'" class="message0" @click.stop>
                    <h1>
                        Rules details
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="rules">
                        <p>1. Reminder: You can only either create or join one spacebase.</p>
                        <p>2. Use SPACE to create a space base, invite fans or friends to join, and both parties can get an extra 5% of the invitee's mining revenue. For example: A creates a space base and invites B to join. B's normal mining income is 100 SPACE, then A and B will each receive an additional 5 SPACE as a space base reward.</p>
                        <p>3. The space base initially has 10 places. Increasing the places will consume more SPACE token. The quantity of SPACE required will vary based on comprehensive data.</p>
                    </div>
                </div>
            </div>
        </div>

        <v-Footer></v-Footer>
    </div>
</template>

<script>
import Web3 from 'web3';
import {changeNumber, numberToFixed} from '@/utils/function.js'
import {
    judgeNetwork,getAllowance,setApprove,getTokenSymbol,
    getBalanceOf,leastUnit,unitPrice,xunitPrice,
    getnameUsersLength,getnameUsers,getSearchNode,
    getNodesLength,getNodeInfo,getNodes,nodeNotes,nodeFeeType,nodeXfee,getUserInviter,getNodeUserLength,getNodeUsers,getNodeLpLength,nodeLpArr,nodeLp,getuserInfo,
    regFromNode,depositNode,setName,setNotes,setnodeJoiningFee,setSocial
} from '@/utils/space.js'
export default {
    name: "Spacebase",
    computed:{
        changeAddress() {
            return (val) => {
                var str = val
                if (str.indexOf('0x') == 0 && str.length == 42) {
                    return str.substring(0,8)+'xxxxxxxxxxxxxxx'+str.substring(36)
                } else {
                    return str
                }
            }
        }
    },
    data() {
        return {
            spacebaseeListLoading:true,
            userAddress: '',tokenSymbol: '',xtokenSymbol: '',
            lpnum:0,lpArr:[],
            token1Symbol: '',token2Symbol: '',token3Symbol: '',
            nodeAddress: '',farmAddress: '',
            searchVal:null,
            totalNumberOfSpacebase:0,mybaseLength:0,
            tabIndex: 0,
            jointhespacebase:[],
            allBaseList:[],
            myBase:[],
            messageBoxShow: false,messageType: '',
            editorOption: {
                placeholder: 'Please fill in a profile for the spacebase',
                modules: {
                    clipboard: {
                    // 粘贴版，处理粘贴时候的自带样式
                    matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
                    },
                },
            },
            TiLength: 0,

            SPACEBalanceof:0,xSPACEBalanceof:0,SPACEBalanceofText:0,xSPACEBalanceofText:0,minNumber:0,unitPrice:0,xunitPrice:0,
            createToken:0,spacebaseMember:null,joiningSPACEToken:false,joiningSPACEPrice:null,joiningxSPACEToken:false,joiningxSPACEPrice:null,spacebaseName:null,groupChatLink:null,spacebaseIntroduction:null,
            btnLoading:false,

            activeItem:{},
            joinToken: null,
            linkUrl:location.href.split('?')[0]+'?',
        }
    },
    created() { },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nodeAddress = window.contractAddr.nodeAddress
        this.farmAddress = window.contractAddr.farmAddress
        this.getNodeLps()
        //console.log(this.linkUrl.split("?")[0])
        //console.log(location.href.split('?')[1])
        if (location.href.split('?')[1]) {
            this.messageBoxShow = true
            this.messageType = 'jointhespacebase'
        }
        setTimeout(() => {
            //console.log(window.newFarmData)
            this.farmList = window.newFarmData
             if (location.href.split('?')[1]) {
                // this.messageBoxShow = true
                // this.messageType = 'jointhespacebase'
                this.getjointheSpacebase()
            }
            if (this.userAddress) {
                this.getMyInfo()
            }
                this.getData()
            
        }, 3000);
        
    },
    methods: {
        changeTokens(){
            this.joiningSPACEToken = this.myBase[0].nodeFeeType == 0 || this.myBase[0].nodeFeeType == 1 ? true : false
            this.joiningxSPACEToken = this.myBase[0].nodeFeeType == 0 || this.myBase[0].nodeFeeType == 2 ? true : false
            this.joiningSPACEPrice = this.myBase[0].nodeFeeType == 0 || this.myBase[0].nodeFeeType == 1 ? this.myBase[0].fee : ''
            this.joiningxSPACEPrice = this.myBase[0].nodeFeeType == 0 || this.myBase[0].nodeFeeType == 2 ? this.myBase[0].xfee : ''
        },
        handleCustomMatcher(node, Delta) {
            const ops = [];
            Delta.ops.forEach((op) => {
                if (op.insert && typeof op.insert === "string") {
                    ops.push({
                        insert: op.insert, 
                        attributes: op.attributes, 
                    });
                } else {
                    if (op.insert && op.insert.image && op.insert.image.indexOf("base64") > -1) {
                        
                    } else {
                        ops.push({
                            insert: op.insert, 
                            attributes: op.attributes, 
                        });
                    }
                }
            });
            Delta.ops = ops;
            return Delta;
        },
        introductionShow(item){
            item.notesShow = !item.notesShow
            this.$forceUpdate()
        },
        getjointheSpacebase(){
            var fartherAddress = location.href.split('?')[1].split('&')[0].split('=')[1]
            getNodeInfo(this.nodeAddress,fartherAddress).then(res => {
                this.jointhespacebase = []
                this.getNodes(this.jointhespacebase,res,'all') 
            })
        },
        getNodeLps(){
            getNodeLpLength(this.nodeAddress).then(lpLength => {
                this.lpnum = lpLength
                for (let i = 0; i < lpLength; i++) {
                    var obj = {
                        contract:'',
                        symbol:''
                    }
                    this.lpArr.push(obj)
                    nodeLpArr(this.nodeAddress,i).then(lpToken => {
                        this.lpArr[i].contract = lpToken
                        if (lpToken == '0x0000000000000000000000000000000000000000') {
                            this.lpArr[i].symbol = localStorage.getItem('nativeCurrencySymbol')
                        }else{
                            getTokenSymbol(lpToken).then(symbol => {
                                this.lpArr[i].symbol = symbol
                            }) 
                        }
                    })
                    
                }
            })
        },
        getMyInfo(){
            getBalanceOf(this.tokenAddress,this.userAddress).then(res => {
                this.SPACEBalanceof = this.$BigNumber(res).div(Math.pow(10,18))
                this.SPACEBalanceofText = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)),6)
            })
            getBalanceOf(this.xTokenAddress,this.userAddress).then(res => {
                this.xSPACEBalanceof = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)),6)
                this.xSPACEBalanceofText = numberToFixed(this.$BigNumber(res).div(Math.pow(10,18)),6)
            })
            leastUnit(this.nodeAddress).then(res => {
                this.minNumber = res
            })
            unitPrice(this.nodeAddress).then(res => {
                this.unitPrice = res / Math.pow(10,18)
            })
            xunitPrice(this.nodeAddress).then(res => {
                this.xunitPrice = res / Math.pow(10,18)
            })
        },
        getData(){
            getNodesLength(this.nodeAddress,this.userAddress).then(nodeNumber => {
                this.allBaseList = []
                this.totalNumberOfSpacebase = nodeNumber
                if(nodeNumber == 0 ){ 
                    this.spacebaseeListLoading = false
                    return false
                }
                for (let i = 0; i < nodeNumber; i++) {
                    this.getNodes(this.allBaseList,i,'all')
                }
            })
            if (this.userAddress) {
                getUserInviter(this.nodeAddress,this.userAddress).then(joinAddress => {
                    getNodeInfo(this.nodeAddress,joinAddress == '0x0000000000000000000000000000000000000000' ? this.userAddress : joinAddress).then(res => {
                        //console.log(res)
                        this.myBase = []
                        this.getNodes(this.myBase,res,'my')
                        
                    })
                })
            }
            
            // this.createSpacebase()
        },
        getNodes(arr,index,type){
            getNodes(this.nodeAddress,index).then(nodeinfo => {
                //console.log(nodeinfo)
                this.spacebaseeListLoading = false
                nodeinfo.totalUnit -= 0
                nodeinfo.id = index
                nodeinfo.fee = nodeinfo.fee/Math.pow(10,18)
                nodeinfo.award = nodeinfo.award/Math.pow(10,18)
                nodeinfo.xaward = nodeinfo.xaward/Math.pow(10,18)
                nodeinfo.awardLptoken3 = 0
                nodeinfo.awardLptoken2 = 0
                nodeinfo.awardLptoken1 = 0
                nodeinfo.tvl = 0
                this.getnodeFeeType(nodeinfo)
                this.getSpacebaseTotalMiningRewards(nodeinfo)
                this.getNodeUserLength(nodeinfo)
                this.getNodeUsers(nodeinfo)
                if (type == 'my') {
                    getUserInviter(this.nodeAddress,this.userAddress).then(joinAddress => {
                        if (joinAddress == '0x0000000000000000000000000000000000000000' && nodeinfo.owner.toLowerCase() != this.userAddress.toLowerCase() ) {
                            return false
                        } else {
                            nodeNotes(this.nodeAddress,index).then(notes => {
                                nodeinfo.notes = notes
                                nodeinfo.notesShow = false
                                arr.push(nodeinfo)
                                //console.log(nodeinfo)
                            })
                        }
                    })
                } else {
                    nodeNotes(this.nodeAddress,index).then(notes => {
                        nodeinfo.notes = notes
                        nodeinfo.notesShow = false
                        arr.push(nodeinfo)
                        arr.sort((a,b) => {
                            if (a.id > b.id) { return -1;
                            } else if (a.id < b.id) { return 1;
                            } else { return 0; }
                        })
                    })
                }
            })
        },
        getnodeFeeType(item){
            nodeFeeType(this.nodeAddress,item.id).then(res => {
                item.nodeFeeType = res
                item.xfee = 0
                this.$forceUpdate()
                if (res == 0 || res == 2) {
                    nodeXfee(this.nodeAddress,item.id).then(res => {
                        item.xfee = res/Math.pow(10,18)
                        this.$forceUpdate()
                    })
                }
            })
        },
        getNodeUserLength(item){
            getNodeUserLength(this.nodeAddress,item.owner).then(userLength => {
                item.joinedMember = userLength
            })
        },
        getNodeUsers(item){
            getNodeUsers(this.nodeAddress,item.owner).then(userList => {
                item.userList = userList
                this.getTvl(item)
            })
        },
        getTvl(item){
            var list = [...item.userList]
            list.push(item.owner)
            list.forEach(userItem => {
                this.farmList.forEach(farmItem => {
                    farmItem.pools.forEach(poolItem => {
                        getuserInfo(this.farmAddress,poolItem.pid,userItem).then(res => {
                            item.tvl += Number((res.amount/Math.pow(10,18)+res.nftAmount/Math.pow(10,18))*farmItem.price)
                        })
                    })
                })
            })
        },
        getSpacebaseTotalMiningRewards(item){
            this.lpArr.forEach((lpItem,lpIndex) => {
                nodeLp(this.nodeAddress,item.id,lpItem.contract).then(res => {
                    if (lpIndex == 0) {
                        item.awardLptoken1 = res.awardLp/Math.pow(10,18)
                    }
                    if (lpIndex == 1) {
                        item.awardLptoken2 = res.awardLp/Math.pow(10,18)
                    }
                    if (lpIndex == 2) {
                        item.awardLptoken3 = res.awardLp/Math.pow(10,18)
                    }
                })
            })
        },
        searchBtn(){
            this.spacebaseeListLoading = true
            if (this.searchVal == null || this.searchVal == '') {
                this.getData()
            } else {
                this.allBaseList = []
                getnameUsersLength(this.nodeAddress,this.searchVal).then(num => {
                    //console.log(num)
                    if (num == 0) {
                        this.spacebaseeListLoading = false
                    } else {
                        for (let i = 0; i < num; i++) {
                            //console.log(num)
                            getnameUsers(this.nodeAddress,this.searchVal,i).then(address => {
                                //console.log(address)
                                getSearchNode(this.nodeAddress,address).then(nodeinfo => {
                                    //console.log(nodeinfo)
                                    this.spacebaseeListLoading = false
                                    nodeinfo.totalUnit -= 0
                                    nodeinfo.id = i
                                    nodeinfo.burn = nodeinfo._burn
                                    nodeinfo.name = nodeinfo._nodeName
                                    nodeinfo.owner = nodeinfo._owner
                                    nodeinfo.social = nodeinfo._social
                                    nodeinfo.totalUnit = nodeinfo._totalUnit
                                    nodeinfo.withdraw = nodeinfo._withdraw
                                    nodeinfo.xaward = nodeinfo._xaward
                                    nodeinfo.xwithdraw = nodeinfo._xwithdraw
                                    nodeinfo.fee = nodeinfo._fee/Math.pow(10,18)
                                    nodeinfo.award = nodeinfo._award/Math.pow(10,18)
                                    nodeinfo.xaward = nodeinfo._xaward/Math.pow(10,18)
                                    nodeinfo.awardLptoken3 = 0
                                    nodeinfo.awardLptoken2 = 0
                                    nodeinfo.awardLptoken1 = 0
                                    this.getnodeFeeType(nodeinfo)
                                    this.getSpacebaseTotalMiningRewards(nodeinfo)
                                    this.getNodeUserLength(nodeinfo)
                                    nodeNotes(this.nodeAddress,i).then(notes => {
                                        nodeinfo.notes = notes
                                        nodeinfo.notesShow = false
                                        this.allBaseList.push(nodeinfo)
                                        this.allBaseList.sort((a,b) => {
                                            if (a.id > b.id) { return -1;
                                            } else if (a.id < b.id) { return 1;
                                            } else { return 0; }
                                        })
                                    })
                                })
                            })
                            
                        }
                    }
                })
            }
        },
        createSpacebase(){
            if (this.myBase.length == 0 && this.spacebaseeListLoading) {
                return false
            }
            if(!this.userAddress){
                this.$notify({type: 'warning', message: 'Please connect wallet first!'})
                return false
            }
            if (this.userAddress !=  '') {
                if (this.myBase.length != 0 ) {
                    this.$message({type:'warning',message:'Created/Joined Spacebase'})
                    return false
                }
                this.messageBoxShow = true
                this.messageType = 'createaspacebase'
                this.getMyInfo()
            }
        },
        createnow(){
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                if (Number(this.spacebaseMember)<Number(this.minNumber)) {
                    this.$message({ type: 'warning', message: 'No less than '+this.minNumber+' places' })
                    this.btnLoading = false
                    return false
                }
                if (this.createToken == 0 && Number(this.spacebaseMember*this.unitPrice) > Number(this.SPACEBalanceof)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.btnLoading = false
                    return false
                }
                if (this.createToken != 0 && Number(this.spacebaseMember*this.xunitPrice) > Number(this.xSPACEBalanceof)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.btnLoading = false
                    return false
                }

                getAllowance(this.createToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res => {
                    //console.log(res)
                    var allowanceNum = res/1e18
                    if (this.createToken == 0 && Number(this.spacebaseMember*this.unitPrice) > Number(allowanceNum) || this.createToken != 0 && Number(this.spacebaseMember*this.xunitPrice) > Number(allowanceNum)) {
                        setApprove(this.createToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res =>{
                            this.createnowafter()
                        }).catch(err => {
                            this.btnLoading = false
                        })
                    } else {
                        this.createnowafter()
                    }
                })
            })
        },
        createnowafter(){
            var usextoken = this.createToken == 0 ? false : true
            var nodeFeeType = this.selectNodeFeeType()
            var relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
            this.spacebaseIntroduction = this.spacebaseIntroduction ? this.spacebaseIntroduction.replace(relStyle,"") : ''
            depositNode(this.nodeAddress,this.spacebaseMember,usextoken,nodeFeeType,this.joiningSPACEPrice || 0,this.joiningxSPACEPrice || 0,this.spacebaseName || this.userAddress,this.groupChatLink || '',this.spacebaseIntroduction || '',this.userAddress).then(res => {
                this.btnLoading = false
                this.getData()
                this.closemessage()
            }).catch(error => {
                //console.log(error)
                this.btnLoading = false
            })
        },
        cardJoinBtn(item,type){
            if (this.myBase.length != 0 ) {
                this.$message({type:'warning',message:'Created/Joined Spacebase'})
                return false
            }
            this.activeItem = item
            //console.log(this.activeItem)
            if (type == 0) {
                this.joinNow()
            } else {
                if (this.activeItem.nodeFeeType == 2) {
                    this.joinToken = 1
                }
                this.messageBoxShow = true
                this.messageType = 'joinaspacebase'
            }
            
        },
        joinNow(){
            if (this.activeItem.nodeFeeType == 3) {
                if ( this.btnLoading ) { return false }
                this.btnLoading = true
                this.joinNowafter()
            } else {
                //console.log(this.activeItem.nodeFeeType,this.joinToken)
                if (this.activeItem.nodeFeeType == 1 && this.joinToken != 0) {
                    this.$message({ type: 'warning', message: 'Please select the token you want to pay with' })
                    return false
                }
                if (this.activeItem.nodeFeeType == 2 && this.joinToken != 1) {
                    this.$message({ type: 'warning', message: 'Please select the token you want to pay with' })
                    return false
                }
                if (this.activeItem.nodeFeeType == 0 && this.joinToken == null) {
                    this.$message({ type: 'warning', message: 'Please select the token you want to pay with' })
                    return false
                }
                if (this.joinToken == 0 && Number(this.SPACEBalanceof) < Number(this.activeItem.fee) || this.joinToken == 1 && Number(this.xSPACEBalanceof) < Number(this.activeItem.xfee)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    return false
                }
                if ( this.btnLoading ) { return false }
                this.btnLoading = true
                judgeNetwork().then(res => {
                    if (res == 0) {
                        this.btnLoading = false
                        return false
                    }
                    getAllowance(this.joinToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res => {
                        var allowanceNum = res/1e18
                        //console.log(allowanceNum)
                        //console.log(this.joinToken == 0 && Number(this.activeItem.fee) > Number(allowanceNum) , this.joinToken != 0 && Number(this.activeItem.xfee) > Number(allowanceNum))
                        if (this.joinToken == 0 && Number(this.activeItem.fee) > Number(allowanceNum) || this.joinToken != 0 && Number(this.activeItem.xfee) > Number(allowanceNum)) {
                            //console.log(this.joinToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress)
                            setApprove(this.joinToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res =>{
                                this.joinNowafter()
                            }).catch(err => {
                                this.btnLoading = false
                            }) 
                        } else {
                            this.joinNowafter()
                        }
                    })
                })
            }
        },
        joinNowafter(){
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                var hash = Web3.utils.soliditySha3(this.activeItem.code, this.userAddress)
                regFromNode(this.nodeAddress,this.activeItem.owner,this.joinToken == 1 ? true : false,hash,this.userAddress).then(res => {
                    location = this.linkUrl.split("?")[0]
                }).catch(error => {
                    this.closemessage()
                })
            })
        },
        changeBaseName(){
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                setName(this.nodeAddress,this.spacebaseName,this.userAddress).then(res => {
                    this.myBase[0].name = this.spacebaseName
                    this.allBaseList.forEach(item => {
                        if(item.owner == this.myBase[0].owner){
                            item.name = this.spacebaseName
                        }
                    });
                    this.closemessage()
                }).catch(err => {
                    this.closemessage()
                })
            })
        },
        changeBaseIntroduction(){
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                var relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
                this.spacebaseIntroduction = this.spacebaseIntroduction.replace(relStyle,"")
                setNotes(this.nodeAddress,this.spacebaseIntroduction,this.userAddress).then(res => {
                    this.myBase[0].notes = this.spacebaseIntroduction
                    this.allBaseList.forEach(item => {
                        if(item.owner == this.myBase[0].owner){
                            item.notes = this.spacebaseIntroduction
                        }
                    });
                    this.closemessage()
                }).catch(err => {
                    this.closemessage()
                })
            })
        },
        changeJoinBasePrice(){
            var nodeFeeType = this.selectNodeFeeType()
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                setnodeJoiningFee(this.nodeAddress,nodeFeeType,this.joiningSPACEPrice,this.joiningxSPACEPrice,this.userAddress).then(res => {
                    // this.getnodeFeeType(this.myBase[0])
                    this.myBase[0].nodeFeeType = nodeFeeType,
                    this.myBase[0].fee = this.joiningSPACEPrice,
                    this.myBase[0].xfee = this.joiningxSPACEPrice
                    setTimeout(() => {
                        this.closemessage()
                    }, 500);
                }).catch(err => {
                    this.closemessage()
                })
            })
        },
        changeBaseGroup(){
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                setSocial(this.nodeAddress,this.groupChatLink,this.userAddress).then(res => {
                    this.myBase[0].social = this.groupChatLink
                    this.allBaseList.forEach(item => {
                        if(item.owner == this.myBase[0].owner){
                            item.social = this.groupChatLink
                        }
                    });
                    this.closemessage()
                }).catch(err => {
                    this.closemessage()
                })
            })
        },
        changeBaseMember(){
            if ( this.btnLoading ) { return false }
            this.btnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.btnLoading = false
                    return false
                }
                if (this.createToken == 0 && Number(this.spacebaseMember*this.unitPrice) > Number(this.SPACEBalanceof)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.btnLoading = false
                    return false
                }
                if (this.createToken != 0 && Number(this.spacebaseMember*this.xunitPrice) > Number(this.xSPACEBalanceof)) {
                    this.$message({ type: 'warning', message: 'Insufficient Balance' })
                    this.btnLoading = false
                    return false
                }
                var usextoken = this.createToken == 0 ? false : true
                getAllowance(this.createToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res => {
                    var allowanceNum = res/1e18
                    if (this.createToken == 0 && Number(this.spacebaseMember*this.unitPrice) > Number(allowanceNum) || this.createToken != 0 && Number(this.spacebaseMember*this.xunitPrice) > Number(allowanceNum)) {
                        setApprove(this.createToken == 0 ? this.tokenAddress : this.xTokenAddress,this.nodeAddress,this.userAddress).then(res =>{
                            depositNode(this.nodeAddress,this.spacebaseMember,usextoken,this.myBase[0].nodeFeeType,this.myBase[0].fee,this.myBase[0].xfee,this.myBase[0].name,this.myBase[0].social,this.myBase[0].notes,this.userAddress).then(res => {
                                this.myBase[0].totalUnit += Number(this.spacebaseMember)
                                this.allBaseList.forEach(item => {
                                    if(item.owner == this.myBase[0].owner){
                                        item.totalUnit += Number(this.spacebaseMember)
                                    }
                                });
                                this.closemessage()
                            }).catch(error => {
                                this.btnLoading = false
                            })
                        }).catch(err => {
                            this.btnLoading = false
                        })
                    } else {
                        depositNode(this.nodeAddress,this.spacebaseMember,usextoken,this.myBase[0].nodeFeeType,this.myBase[0].fee,this.myBase[0].xfee,this.myBase[0].name,this.myBase[0].social,this.myBase[0].notes,this.userAddress).then(res => {
                            this.myBase[0].totalUnit += Number(this.spacebaseMember)
                            this.allBaseList.forEach(item => {
                                if(item.owner == this.myBase[0].owner){
                                    item.totalUnit += Number(this.spacebaseMember)
                                }
                            });
                            this.closemessage()
                        }).catch(error => {
                            this.btnLoading = false
                        })
                    }
                })
            })
        },
        copyLink(){
            var oInput = document.createElement('input');
            oInput.value = this.linkUrl+ 'address=' + this.userAddress + '&code=' + this.myBase[0].code;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand("Copy");
            oInput.className = 'oInput';
            oInput.style.display='none';
            this.$message({ type: 'success', message: 'Copy invitation link successfully' });
        },

        selectNodeFeeType(){
            if(!this.joiningSPACEToken && !this.joiningxSPACEToken ){
                return 3
            } else if(this.joiningSPACEToken && !this.joiningxSPACEToken){
                return 1
            } else if(!this.joiningSPACEToken && this.joiningxSPACEToken){
                return 2
            }else if(this.joiningSPACEToken && this.joiningxSPACEToken){
                return 0
            }
        },
        changeIntroduction(events){
            this.TiLength = events.text.length-1
            events.quill.deleteText(140,1);
        },
        closemessage(){
            if (location.href.split('?')[1] && this.jointhespacebase.length == 0) {
                return false
            }
            this.messageBoxShow = false
            this.messageType = ''
            this.createToken = 0
            this.spacebaseMember = null
            this.joiningSPACEToken = false
            this.joiningSPACEPrice = null
            this.joiningxSPACEToken = false
            this.joiningxSPACEPrice = null
            this.spacebaseName = null
            this.groupChatLink = null
            this.spacebaseIntroduction = null
            this.btnLoading = false
            this.joinToken = null
        },
    }
}
</script>